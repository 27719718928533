<template>
  <div class="popup-wrapper" :style="!popupOpen ? { display: 'none'} : {}">
    <div class="popup-container">
      <div class="popup-header">
        <div class="popup-title">
          CONTENT
        </div>
        <a class="popup-button button-close" @click="$emit('popup-close')">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="popup-content">
        <div class="form-container-inline form-container">
            <p>{{ "Language" }}</p>
            <select v-model="selectedLanguage" @change="languageChanged($event)">
              <option v-for="language in languages" v-bind:key="language.code" :value="language.code">{{ language.name }}</option>
            </select>
          </div>
        <div class="form-container-inline form-container">
          <p>{{ $t('ARENA') }}</p>
          <input :disabled="arena.editMode" v-model.number="arena.arenaNumber" />
        </div>
        <div class="form-container-inline form-container">
          <p>{{ $t('NAME') }}</p>
          <input v-model="state.name" />
        </div>
        <div class="form-container">
          <p>{{ "Localized Name" }}</p>
          <input v-model="state.localizables.name[selectedLanguage]"/>
        </div>
        <div class="form-container-inline form-container">
          <p>{{ "Product ID" }}</p>
          <input v-model="state.product_id" />
        </div>
        <div class="form-container-inline form-container">
          <p>{{ $t('PRICE') }}</p>
          <input v-model.number="state.price" />
        </div>
        <div class="form-container-inline form-container">
          <p>{{ $t('DISCOUNT') }}</p>
          <input v-model.number="state.discount_rate" />
        </div>
        <div class="form-container-inline form-container">
          <p>{{ $t('PROMOTION_VALUES.VALUE_MULTIPLIER') }}</p>
          <input v-model.number="state.value_multiplier" />
        </div>
        <div class="form-container-inline form-container">
          <p>{{ "Display Priority (Lower is higher)" }}</p>
          <input v-model.number="state.priority" />
        </div>
        <div class="form-container">
          <p>{{ "Layout" }}</p>
          <select v-model="state.layout_index">
            <option v-for="option in promotionConstants.LAYOUT_OPTIONS" :value="option.value" v-bind:key="option.value">{{ option.text || "DEFAULT" }}</option>
          </select>
        </div>
        <div class="form-container">
          <p>{{ "Popup Type" }}</p>
          <select v-model="state.popup_type">
            <option v-for="option in promotionConstants.POPUP_TYPE_OPTIONS" :value="option.value" v-bind:key="option.value">{{ option.text || "DEFAULT" }}</option>
          </select>
        </div>
        <div class="form-container-inline form-container">
          <p>{{ $t(`PROMOTION_VALUES.SHOP_BANNER_ASSET`) }}</p>
          <select v-model="state.shop_banner_asset">
            <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
          </select>
        </div>
        <div class="form-container-inline form-container">
          <p>{{ $t(`PROMOTION_VALUES.POPUP_BANNER_ASSET`) }}</p>
          <select v-model="state.popup_banner_asset">
            <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
          </select>
        </div>
        <div class="form-container-inline form-container">
          <p>{{ $t(`PROMOTION_VALUES.MINI_BANNER_ASSET`) }}</p>
          <select v-model="state.mini_banner_asset">
            <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
          </select>
        </div>
        <div class="reward-container">
          <h3>{{ $t('REWARDS') }}</h3>
          <div class="form-container-inline form-container">
            <p>{{ $t('GOLD') }}</p>
            <input v-model.number="state.reward.gold" />
          </div>
          <div class="form-container-inline form-container">
            <p>{{ $t('GEM') }}</p>
            <input v-model.number="state.reward.gem" />
          </div>
          <div class="form-container">
            <p>{{ $t('CHESTS' )}}</p>
            <div class="reward-table" v-if="state.reward.chests.length > 0">
              <div class="row row-header chest-row">
                <div class="reward-cell">{{ $t('REWARD_VALUES.CHEST_ID') }}</div>
                <div class="reward-cell">{{ $t('REWARD_VALUES.CHEST_ARENA') }}</div>
                <div class="reward-cell">{{ $t('REWARD_VALUES.AMOUNT') }}</div>
                <div class="reward-cell">{{ $t('REMOVE') }}</div>
              </div>
              <div class="row chest-row" v-for="(chest, index) in state.reward.chests" v-bind:key="index">
                
                <div class="reward-cell">
                  <select v-model="state.reward.chests[index].id">
                    <option v-for="option in chests" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
                  </select>
                </div>
                <div class="reward-cell"><input v-model.number="state.reward.chests[index].arena" /></div>
                <div class="reward-cell"><input v-model.number="state.reward.chests[index].amount" /></div>
                <div class="reward-cell">
                  <button @click="state.reward.chests.splice(index, 1)" class="error">{{ $t('REMOVE') }}</button>
                </div>
              </div>
            </div>
            <button @click="addChest" class="success">{{ $t('ADD_CHEST') }}</button>
          </div>
        </div>
      </div>
      <div class="popup-footer">
        <a class="popup-button button-save" @click="emitSave">
          <i class="fas fa-check"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch, computed, ref } from "vue";
import { useStore } from "vuex";
import arenaPackSchema from '@/schemas/arenaPack'
import { notify } from "@kyvg/vue3-notification";
import languageConstants from "@/constants/languagesTemp";
import promotionConstants from "../constants/promotionConstants"

export default {
  name: "ArenaPackPopup",
  props: ["content", "dataIndex", "popupOpen"],
  emits: ["popup-close"],
  setup(props, { emit }) {
    const store = useStore();
    const units = computed(() => store.getters['units/getUnits']);
    const heroes = computed(() => store.getters['heroes/getHeroes']);
    const chests = computed(() => store.getters['chests/getChests']);

    const selectedLanguage = ref('en')
    const languages = languageConstants.languages;

    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })

    const state = reactive({
      app_store_sku: "",
      play_store_sku: "",
      name: "",
      localizables: {
        name: {}
      },
      price: 0,
      discount_rate: 0,
      layout_index: 0,
      popup_type: 0,
      priority: 0,
      value_multiplier: 1,
      reward: {
        gold: 0,
        gem: 0,
        token: 0,
        chests: [],
        units: [],
        heroes: []
      }
    })

    const arena = reactive({
      editMode: false,
      arenaNumber: 1
    });

    const packList = ref([])

    watch(() => props.content, value => {
      packList.value = value.packlist;
      if(value.index !== 0) {
        arena.editMode = true;
        arena.arenaNumber = value.index;
        state.product_id = value.product_id;
        state.localizables = value.localizables||{ name: {} };
        state.name = value.name;
        state.price = value.price;
        state.layout_index = value.layout_index||0;
        state.priority = value.priority||0;
        state.discount_rate = value.discount_rate;
        state.reward = JSON.parse(JSON.stringify(value.reward));
        state.value_multiplier = value.value_multiplier;
        state.shop_banner_asset = value.shop_banner_asset;
        state.mini_banner_asset = value.mini_banner_asset;
        state.popup_banner_asset = value.popup_banner_asset;
        state.popup_type = value.popup_type ?? 0;
      }  else {
        arena.editMode = false;
        arena.arenaNumber = 1;
        state.product_id = "";
        state.app_store_sku = "";
        state.play_store_sku = "";
        state.name = "";
        state.localizables = { name: {} }
        state.price = 0;
        state.discount_rate = 0;
        state.reward = { ...arenaPackSchema().reward }
        state.value_multiplier = 1;
        state.layout_index = 0;
        state.priority = 0;
        state.shop_banner_asset = value.shop_banner_asset;
        state.mini_banner_asset = value.mini_banner_asset;
        state.popup_banner_asset = value.popup_banner_asset;
        state.popup_type = 0;
      }
    })

    const addChest = () => {
      state.reward.chests.push({
        id: "",
        arena: 1,
        amount: 1
      })
    }

    const emitSave = () => {
      if(!arena.editMode && packList.value.includes(arena.arenaNumber.toString())) {
        notify({
          title: 'Invalid Operation',
          text: 'An arena pack already exist for specified arena.',
          type: 'error'
        })
      } else {
        emit('popup-save', state, arena)
      } 
    }

    return {
      emitSave,
      state,
      units,
      heroes,
      addChest,
      arena,
      chests,
      assetList,
      selectedLanguage,
      languages,
      promotionConstants
    }
  }
}
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container {
  width: 70%;
  height: 60%;
  border-radius: 25px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-content {
  width: 100%;
  height: 90%;
  overflow: scroll;

}
.popup-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.popup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.popup-button {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 5px 15px;
}

.button-close {
  color: red;

}

.button-save {
  color: green;
}

.popup-title {
  font-size: 1.3rem;
  font-weight: bold;
}

.reward-table {
  width: 100%;
}
.row {
  width: 100%;
  display: grid;
}

.chest-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
</style>
export default () => {
    return {
        app_store_sku: "",
        play_store_sku: "",
        name: "",
        localizables: {
            name: {}
        },
        price: 0,
        discount_rate: 0,
        layout_index: 0,
        popup_type: 0,
        priority: 0,
        reward: {
            gold: 0,
            gem: 0,
            token: 0,
            chests: [],
            units: [],
            heroes: []
        }
    }
}